body{
    background-image: url(SHT.jpg) ;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    margin: 0%;
    overflow: hidden;
    
   
}

.box{
    background-color: rgba(241, 246, 246, 0.292);
    padding: 30px;
    height: 100vh;
    z-index: 100;    
    max-width: 76vh;
    margin-right: 0%;
    margin-left: auto;
    }

.text{

    font-size: large;
    color: blue;
    z-index: 120;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
   margin-top: 20vh;
   line-height: 70px;
}

